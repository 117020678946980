export var WorkflowCreationSourceEnum;
(function (WorkflowCreationSourceEnum) {
    WorkflowCreationSourceEnum["TEMPLATE_STORE"] = "template_store";
    WorkflowCreationSourceEnum["EDITOR"] = "editor";
    WorkflowCreationSourceEnum["NOTIFICATION_DIRECTORY"] = "notification_directory";
    WorkflowCreationSourceEnum["ONBOARDING_DIGEST_DEMO"] = "onboarding_digest_demo";
    WorkflowCreationSourceEnum["ONBOARDING_IN_APP"] = "onboarding_in_app";
    WorkflowCreationSourceEnum["EMPTY_STATE"] = "empty_state";
    WorkflowCreationSourceEnum["DROPDOWN"] = "dropdown";
    WorkflowCreationSourceEnum["ONBOARDING_GET_STARTED"] = "onboarding_get_started";
    WorkflowCreationSourceEnum["BRIDGE"] = "bridge";
    WorkflowCreationSourceEnum["DASHBOARD"] = "dashboard";
})(WorkflowCreationSourceEnum || (WorkflowCreationSourceEnum = {}));
